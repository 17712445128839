<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <p>{{ msg }}</p>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      msg: "Loading...",
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.get('/api/msg/')
          .then(res => this.msg = res.data.msg);
    }
  }
}
</script>
